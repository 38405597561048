import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import OrcidIcon from '../assets/images/ORCID-iD_icon_BW_32x32.png';
import OrcidReversedIcon from '../assets/images/ORCID-iD_icon_reversed_32x32.png';

function SocialsComponent() {

    return (
        <>
            <a href="https://www.linkedin.com/in/ondrejstrnad/" target="_blank" rel="noreferrer"><LinkedInIcon /></a>
            <a href="https://www.facebook.com/ondrej.strnad.33" target="_blank" rel="noreferrer"><FacebookIcon /></a>
            <a href="https://www.instagram.com/ondrej__strnad/" target="_blank" rel="noreferrer"><InstagramIcon /></a>
            <a href="https://github.com/ondrejstrnad" target="_blank" rel="noreferrer"><GitHubIcon /></a>
            <a href="https://orcid.org/0000-0002-8077-4692" target="_blank" rel="noreferrer"><img src={OrcidIcon} /></a>
        </>
    );
}

export default SocialsComponent;


