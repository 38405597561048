import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/Timeline.scss'
import MuniLogo from '../assets/images/logos/muni.jpg'
import SgpceLogo from '../assets/images/logos/sgpce.jpg'

import { Grid } from "@mui/material";

const education = [
  {
    date: "",
    degree: null,
    place: "Online Courses",
    thesis: "ReactJS, TypeScript, CI/CD for Software Developers, Neural Networks and Deep Learning",
    icon: undefined,
  },
  {
    date: "2009 - 2014",
    degree: "PhD Computer Science",
    place: "Faculty of Informatics, Masaryk University, Brno",
    thesis: "Algorithms for Detecting Pathways in Large Protein Structures and Their Ensembles",
    thesis_link: "https://is.muni.cz/th/lkrh1/thesis_strnad.pdf?info",
    icon: MuniLogo
  },
  {
    date: "2007 - 2009",
    degree: "MSc Computer Graphics",
    place: "Faculty of Informatics, Masaryk University, Brno",
    thesis: "Free-form Deformations with Catmull-Clark Volumes",
    thesis_link: "https://is.muni.cz/th/py7bf/diplomka.pdf?info",
    icon: MuniLogo
  },
  {
    date: "2004 - 2007",
    degree: "BSc Applied Informatics",
    place: "Faculty of Informatics, Masaryk University, Brno",
    thesis: "Sweep-based Freeform Deformations",
    thesis_link: "https://is.muni.cz/th/139568/fi_b/bcprace.pdf?info",
    icon: MuniLogo
  },
  {
    date: "2004 - 2009",
    degree: "Graduation - Sport education, theory of sport",
    place: "Sport High School, Pardubice, Czech Republic",
    thesis: null,
    thesis_link: null,
    icon: SgpceLogo
  }
]


function Education() {
  return (
    <div id="education">
      <div className="items-container">
        <h1>Education</h1>

        <VerticalTimeline layout="1-column-left">
          {
            education.map(e =>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'white', color: 'rgb(39, 40, 34)' }}
                contentArrowStyle={{ borderRight: '7px solid  white' }}
                iconStyle={{ background: '#1E90FF', color: 'rgb(39, 40, 34)' }}
                icon={<FontAwesomeIcon icon={faGraduationCap} />}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <h3 className="vertical-timeline-element-title">{e.degree}</h3>
                    <h4 className="vertical-timeline-element-subtitle">{e.place}</h4>
                  </Grid>
                  <Grid item xs={4}>
                    <img src={e.icon} width={70} />
                  </Grid>
                  <Grid item xs={12}>
                    <p>
                      {e.thesis ?
                        <p>
                          {e.degree ? <a href={e.thesis} target="_blank">{"Thesis: " + e.thesis}</a>
                            :
                            e.thesis}
                        </p>
                        : <></>
                      }
                    </p>
                  </Grid>
                </Grid>
              </VerticalTimelineElement>
            )
          }
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default Education;