import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/Timeline.scss'
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function Hobbies() {
  const images = [
    {
      original: "panorama/IMG_0410.jpg",
      thumbnail: "panorama/IMG_0410_thumb.jpg",
      description: "Hegra / Al Ula, Saudi Arabia"
    },
    {
      original: "panorama/IMG_0782.jpg",
      thumbnail: "panorama/IMG_0782_thumb.jpg",
      description: "Red Sea, Rabigh, Saudi Arabia"
    },
    {
      original: "panorama/IMG_0783.jpg",
      thumbnail: "panorama/IMG_0783_thumb.jpg",
      description: "Al Shafa Mountain, Saudi Arabia"
    },
    {
      original: "panorama/IMG_0995.jpg",
      thumbnail: "panorama/IMG_0995_thumb.jpg",
      description: "Elephant Orphanage, Sri Lanka"
    },
    {
      original: "panorama/IMG_1195.jpg",
      thumbnail: "panorama/IMG_1195_thumb.jpg",
      description: "Sigiriya, Sri Lanka"
    },    
    {
      original: "panorama/IMG_1122.jpg",
      thumbnail: "panorama/IMG_1122_thumb.jpg",
      description: "Red Sea, KAUST, Saudi Arabia"
    },    
    {
      original: "panorama/IMG_1656.jpg",
      thumbnail: "panorama/IMG_1656_thumb.jpg",
      description: "Gosausee, Austria"
    },
    {
      original: "panorama/IMG_2084.jpg",
      thumbnail: "panorama/IMG_2084_thumb.jpg",
      description: "Elephant Rock, Al Ula, Saudi Arabia"
    },
    {
      original: "panorama/IMG_3960.jpg",
      thumbnail: "panorama/IMG_3960_thumb.jpg",
      description: "Bohemian Switzerland, Czech Republic"
    },
    {
      original: "panorama/IMG_4856.jpg",
      thumbnail: "panorama/IMG_4856_thumb.jpg",
      description: "Melbourne, Australia"
    },
    {
      original: "panorama/IMG_4981.jpg",
      thumbnail: "panorama/IMG_4981_thumb.jpg",
      description: "Great Ocean Road, Australia"
    },
    {
      original: "panorama/IMG_5287.jpg",
      thumbnail: "panorama/IMG_5287_thumb.jpg",
      description: "Nove Mlyny, Czech Republic"
    },    
    {
      original: "panorama/IMG_5299.jpg",
      thumbnail: "panorama/IMG_5299_thumb.jpg",
      description: "Saalbach, Austria"
    },  
    {
      original: "panorama/IMG_5436.jpg",
      thumbnail: "panorama/IMG_5436_thumb.jpg",
      description: "Kuala Lumpur, Malaysia"
    },
    {
      original: "panorama/IMG_5782.jpg",
      thumbnail: "panorama/IMG_5782_thumb.jpg",
      description: "Langkawi, Malaysia"
    },
    {
      original: "panorama/IMG_6012.jpg",
      thumbnail: "panorama/IMG_6012_thumb.jpg",
      description: "Brno, Czech Republic"
    },
    {
      original: "panorama/IMG_7145.jpg",
      thumbnail: "panorama/IMG_7145_thumb.jpg",
      description: "Iru Fushi, Maldives"
    },
    {
      original: "panorama/IMG_7630.jpg",
      thumbnail: "panorama/IMG_7630_thumb.jpg",
      description: "Rome, Italy"
    },
    {
      original: "panorama/IMG_7766.jpg",
      thumbnail: "panorama/IMG_7766_thumb.jpg",
      description: "Zandvoort, The Netherlands"
    },
    {
      original: "panorama/IMG_8324.jpg",
      thumbnail: "panorama/IMG_8324_thumb.jpg",
      description: "Alta Badia, Italy"
    },
    {
      original: "panorama/IMG_8402.jpg",
      thumbnail: "panorama/IMG_8402_thumb.jpg",
      description: "Arabba, Italy"
    }
  ];

  return (
    <div id="hobbies">
      <div className="items-container">
        <h1>Hobbies</h1>
        <p>I have a deep passion for all kinds of sports, with a particular love for golf, tennis, soccer, and running. Each sport brings its own unique thrill, whether it’s the precision and focus required in golf, the agility and strategy in tennis, the teamwork and excitement of soccer, or the endurance and clarity of mind that comes with running. Beyond sports, I find immense joy in listening to music and exploring its creative side through DJing, which allows me to connect with rhythms and melodies on a deeper level. I also have a love for diving, as it opens up an entirely different world of serenity and wonder beneath the surface. I absolutely love traveling, as it gives me the chance to experience new cultures, meet fascinating people, and explore the beauty and diversity of the world. On top of that, I enjoy capturing the beauty of landscapes through panoramic photography, where I can immortalize vast and breathtaking scenes. </p>
        
        <ImageGallery
          items={images}
          showPlayButton={true}
          showFullscreenButton={true}
          slideInterval={3000}
          slideOnThumbnailOver={true}
          showIndex={true}
          onPlay={() => {
            alert("slideshow is now playing!");
          }}
        />
        
      </div>
    </div>
  );
}

export default Hobbies;