import React from "react";
import Chip from '@mui/material/Chip';
import '../assets/styles/Project.scss';

import TeracellImage from '../assets/images/projects/teracellviewer.jpg'
import MesoCraftImage from '../assets/images/projects/mesocraft.jpg'
import DevelSlaveImage from '../assets/images/projects/develslave.jpg'
import CompeeteeImage from '../assets/images/projects/compeetee.jpg'
import CaverImage from '../assets/images/projects/caver.jpg'
import HlavacovaImage from '../assets/images/projects/hlavacova.jpg'
import ImpressImage from '../assets/images/projects/impress.jpg'
import MicrocsImage from '../assets/images/projects/microcs.jpg'
import SofteeImage from '../assets/images/projects/softeee.jpg'
import LabSoftImage from '../assets/images/projects/labsoft.jpg'
import VGraphImage from '../assets/images/projects/vgraph.jpg'
import LabCAMImage from '../assets/images/projects/labcam.jpg'

import { Card } from "@mui/material";

const projects = [
    {
        name: "TeraCellViewer",
        link: "",
        image: TeracellImage,
        description: "A software framework for visualizing and analyzing large out-of-core oil reservoir datasets. The tool enables real-time interaction, data selection, filtering, arithmetic operations, and multi-attribute visualization. It features split views, integrated charting with VegaLite syntax support, and a wide range of advanced capabilities for effective data exploration.",
        stack: ["C++", "Vulkan", "Qt", "GLSL", "VR/XR"]
    },
    {
        name: "MesoCraft",
        link: "",
        image: MesoCraftImage,
        description: "Software tool for the procedural generation of massive biological assemblies consisting of billions of atoms. Developed in both desktop and web versions.",
        stack: ["C++", "OpenGL", "Qt", "GLSL", "WebGPU", "WebAssembly", "VR/XR"]
    },
    {
        name: "Caver / Caver Analyst",
        link: "https://www.caver.cz",
        image: CaverImage,
        description: "Protein analysis software toolkit focused on protein visualization and the interactive computation and analysis of protein tunnels and channels.",
        stack: ["Java", "NetBeans", "JOGL"]
    },
    {
        name: "www.fotohlavacova.cz",
        link: "https://www.fotohlavacova.cz",
        image: HlavacovaImage,
        description: "Personal web of local photographer based in Brno, Czech Republic",
        stack: ["Wordpress", "PHP", "CSS"]
    },
    {
        name: "www.develslave.com",
        link: "https://www.develslave.com",
        image: DevelSlaveImage,
        description: "A web-based suite of utilities for software development I built to make my professional life easier. Personal project.",
        stack: ["JavaScript", "TypeScript", "ReactJS", "Material UI", "CI/CD", "Node.js"]
    },
    {
        name: "www.compeetee.com",
        link: "https://www.compeetee.com",
        image: CompeeteeImage,
        description: "Upcoming personal project...",
        stack: ["JavaScript", "TypeScript", "ReactJS", "Material UI", "MongoDB", "MongoDB Atlas", "REST API", "CI/CD", "Node.js"]
    },
    {
        name: "ImPress",
        link: "https://www.delong.cz",
        image: ImpressImage,
        description: "Visual programming tool used for internal testing of devices. Communicates via API with microCS and stores data to LabSoft.",
        notice: "Proprietary tool of DELONG INSTRUMENTS a.s.",
        stack: ["C#", ".NET", "WinForms"]
    },
    {
        name: "microCS",
        link: "https://www.delong.cz",
        image: MicrocsImage,
        description: "Control system of eletron microscopes. Communication with hardware, security, automatic procedures and dependencies, integration of several cameras from different vendors, graphical user interface. Multi-window design.",
        notice: "Proprietary tool of DELONG INSTRUMENTS a.s.",
        stack: ["C/C#", ".NET", "WinForms", "WCF", "Windows Service", "Protobuf"]
    },
    {
        name: "Softeee",
        link: "https://www.delong.cz",
        image: SofteeImage,
        description: "Version management system for microCS and related device-specific settings scripts.",
        notice: "Proprietary tool of DELONG INSTRUMENTS a.s.",
        stack: ["C#", "WPF", "Entity Framework", "MySql"]
    },
    {
        name: "LabSoft",
        link: "https://www.delong.cz",
        image: LabSoftImage,
        description: "Internal CMS system that constains tasks and warehouse management, and manufacturing diaries with checklist of individual production hardware components and devices.",
        notice: "Proprietary tool of DELONG INSTRUMENTS a.s.",
        stack: ["C#", ".NET", "WinForms", "WPF", "MySql"]
    },
    {
        name: "VGraph",
        link: "https://www.delong.cz",
        image: VGraphImage,
        description: "A software utility designed to generate detailed graphs and visualizations from log files recorded during the manufacturing processes of hardware components and devices.",
        notice: "Proprietary tool of DELONG INSTRUMENTS a.s.",
        stack: ["C#", ".NET", "WinForms"]
    },
    {
        name: "LabCAM",
        link: "https://www.delong.cz",
        image: LabCAMImage,
        description: "A scalable and user-friendly software interface designed for seamless integration and operation of cameras from multiple vendors, ensuring compatibility and comparability of acquired results.",
        notice: "Proprietary tool of DELONG INSTRUMENTS a.s.",
        stack: ["C#", ".NET", "WinForms"]
    }
]

/*
 {
    name: "",
    link: "",
    image: mock01,
    description: ""
}
    */

function Project() {
    return (
        <div className="projects-container" id="projects">
            <h1>Projects</h1>
            <div className="projects-grid">
                {
                    projects.map(p =>
                        <div className="project">
                            <a href={p.link} target="_blank" rel="noreferrer"><h2>{p.name}</h2></a>
                            <Card>
                            <a href={p.link} target="_blank" rel="noreferrer">
                                <img src={p.image} className="zoom" alt="thumbnail" width="100%" />    
                            </a>
                            </Card>
                            <p>{p.description}</p>
                            <p><i>{p.notice}</i></p>
                            <div className="flex-chips">
                                {p.stack?.map((label, index) => (
                                    <Chip key={index} className='chip' label={label} />
                                ))}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default Project;