import React from "react";
import ondrej from '../assets/images/ondrej.strnad.jpg';
import '../assets/styles/Main.scss';
import SocialsComponent from "./SocialsComponent";

function Main() {

  return (
    <div className="container">
      <div className="about-section">
        <div className="image-wrapper">
          <img src={ondrej} alt="Avatar" />
        </div>
        <div className="content">
          <div className="social_icons">
            <SocialsComponent />
          </div>
          <h1>Ondrej Strnad</h1>
          <p>Research Scientist / Full Stack Developer</p>

          <div className="mobile_social_icons">
            <SocialsComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;