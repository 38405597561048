import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import '../assets/styles/Footer.scss'
import SocialsComponent from "./SocialsComponent";

function Footer() {
  return (
    <footer>
      <div>
        <SocialsComponent />        
      </div>
    </footer>
  );
}

export default Footer;